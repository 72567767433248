import React from 'react';
import { Card, Avatar, Typography, Space, Tag, Button } from '@arco-design/web-react';

const PeopleCard = () => {
    return (
        <Card
            style={{
                width: 600,
                border: '1px solid #f0f0f0',
                padding: '16px',
                position: 'relative',
                boxShadow: '0 6px 20px rgba(0, 0, 0, 0.15)',
            }}
        >
            <Tag color="green" style={{ position: 'absolute', top: 16, right: 16 }}>医美护肤</Tag>
            <Space style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Space size={16}>
                    <Avatar size={80} src="https://pic1.zhimg.com/70/v2-53c1bc420d925bbc55d3ebddc1f1a091_1440w.avis?source=172ae18b&biz_tag=Post" />
                    <div>
                        <Typography.Text style={{ fontSize: 18, fontWeight: 'bold' }}>小希莉莉</Typography.Text>
                        <Typography.Text type="secondary" style={{ display: 'block', marginTop: 4 }}>
                            壇长医美护肤品类目销售
                        </Typography.Text>
                    </div>
                </Space>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 16 }}>
                    <Button type="primary" style={{ marginRight: 8 }}>聊天</Button>
                    <Button type="primary">雇佣</Button>
                </div>
            </Space>
        </Card>
    );
};

export default PeopleCard;
