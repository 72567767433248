import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Message, Modal} from '@arco-design/web-react';
import {IconPlus, IconWechat} from '@arco-design/web-react/icon';
import '@arco-design/web-react/dist/css/arco.css';
import {bindAgentApi, getWxAccountsApi, loginWechatApi} from '../../../../api/normalApi';
import {QRCodeSVG} from 'qrcode.react';

const LaunchModal = () => {
    const [visible, setVisible] = useState(false);//
    const [selectedAccounts, setSelectedAccounts] = useState([]); // 用于存储选中的账号 ID 列表
    const [intervalActive, setIntervalActive] = useState(false);//控制5s请求一次getWxAccount
    const [accounts, setAccounts] = useState([
        {
            id: 'account2',
            label: '个人微信',
            platform: 'wechat',
            icon: <IconWechat style={{ fontSize: 20, color: '#07c160', marginRight: '10px',marginTop:5 }} />,
            status: '未登录',
            statusColor: '#1890FF',
            button: <Button size='mini' type="outline" onClick={() => handleLoginClick('account2')}>登录账号</Button>,
        }
    ]);
    const [qrCode, setQrCode] = useState(null)//展示登陆二维码
    const [len, setLen] = useState(0)//当前账号数量

    const handleOpen = () => {
        setVisible(true);
    };

    const handleClose = () => {
        setVisible(false);
        setSelectedAccounts([])
    };

    const handleAccountChange = (checkedValues) => {
        setSelectedAccounts(checkedValues);
    };

    const addAccounts = () => {
        if (accounts.length >= 5) {
            Message.warning('只能添加5个');
            return;
        }
        const newAccount = {
            id: `account${accounts.length + 1}`,
            label: '个人微信',
            platform: 'wechat',
            icon: <IconWechat style={{ fontSize: 20, color: '#07c160', marginRight: '10px' }} />,
            status: '未登录',
            statusColor: '#1890FF',
            button: <Button type="outline" size='mini' onClick={() => handleLoginClick(`account${accounts.length + 1}`)}>登录账号</Button>
        };
        setAccounts(prevState => [...prevState, newAccount]);
    };

    const handleLoginClick = async (accountId) => {//登陆函数：先判断是否有在登陆的账号，再执行loginApi
        setAccounts(prevAccounts => {
            return prevAccounts.map(account => {
                if (account.status === '登陆中' && account.id !== accountId) {
                    // 找到上一个“登陆中”的账号，并将其状态恢复为“未登录”
                    return {
                        ...account,
                        status: '未登录',
                        statusColor: '#1890FF',
                        button: <Button type="outline" size='mini'
                                        onClick={() => handleLoginClick(account.id)}>登录账号</Button>
                    };
                } else if (account.id === accountId) {
                    // 设置当前账号为“登陆中”
                    return {
                        ...account,
                        status: '登陆中',
                        statusColor: '#FF8C00',
                        button: <Button type="outline" size='mini'
                                        onClick={() => cancelLogin(accountId)}>取消登录</Button>
                    };
                }
                return account;
            });
        });

        await loginWechatApi().then(data => {
            if (data && data.data && data.data.qr_code) {
                setQrCode(data.data.qr_code)
                // 开始每隔5秒调用一次 getAllWxAccounts
                setIntervalActive(true); // 激活定时器
            }
            else {
                console.log("no loginWX data")
            }
        }).catch(err => console.log(err))

    };

    const getAllWxAccounts = async () => {//每隔5s请求一次
        try {
            const data = await getWxAccountsApi();

            if (data && data.data && data.data.length > 0) {

                // 使用返回的数据来更新 accounts 状态
                const updatedAccounts = data.data.reduce((acc, account) => {
                    // 使用wx_user_id作为唯一标识符
                    if (!acc.some(existingAccount => existingAccount.id === account.wx_user_id)) {
                        acc.push({
                            id: account.wx_user_id,
                            label: account.wx_nickname,
                            platform: 'wechat',
                            icon: <IconWechat style={{ fontSize: 20, color: '#07c160', marginRight: '10px' }} />,
                            status: '已登录',
                            statusColor: 'green',
                            button: null,
                        });
                    }
                    return acc;
                }, []);

                setAccounts(updatedAccounts);
                if (updatedAccounts.length > len) {
                    setIntervalActive(false); // 停止定时器
                    setQrCode(null)//二维码不显示
                    setLen(data.data.length)

                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    const cancelLogin = (accountId) => {//取消登陆
        setIntervalActive(false)
        setAccounts(prevAccounts => prevAccounts.map(account => {
            if (account.id === accountId) {
                return {
                    ...account,
                    status: '未登录',
                    statusColor: '#1890FF',
                    button: <Button type="outline" size='mini' onClick={() => handleLoginClick(accountId)}>登录账号</Button>
                };
            }
            return account;
        }));
        setQrCode(null)
    };

    const bindAgent = async () => {//绑定微信代理
        if (selectedAccounts.length > 0) {
            await bindAgentApi({
                "wx_user_ids": selectedAccounts,
                "assistant_id": "asst_d9O8cE5k3OcDrRwWLsd3jvvF"
            }).then(data => {
                if (data.status === 200){
                    Message.success('绑定成功')
                    setVisible(false);
                }

            })
        } else {
            Message.warning("请选择需要发布的账号")
        }

    }

    useEffect(() => {//初始获取账号
        getAllWxAccounts()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {// 清除定时器
        let intervalId;

        if (intervalActive) {
            intervalId = setInterval(async () => {
                await getAllWxAccounts();
            }, 5000);
        }
        return () => clearInterval(intervalId);
        // eslint-disable-next-line
    }, [intervalActive]);

    return (
        <div>
            <Button type='outline' onClick={handleOpen}>
                发布
            </Button>
            <Modal
                visible={visible}
                onCancel={handleClose}
                onOk={() => handleClose}
                footer={<Button type="primary" size="small" onClick={bindAgent}>
                    发布更新
                </Button>}
                title={<h3 style={{ textAlign: 'left', marginBottom: '20px' }}>发布平台</h3>}
                simple={true}
                style={{ width: 600, height: 'auto', textAlign: 'center' }}
            >
                <div style={{ width: '100%' }}>
                    <Checkbox.Group value={selectedAccounts} onChange={handleAccountChange} style={{ width: '100%' }}>
                        {accounts.map((account) => (
                            <div key={account.id} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                                <div style={{ flex: '0 0 15px' }}>
                                    <Checkbox value={account.id} disabled={account.status !== '已登录'} />
                                </div>
                                <div style={{ flex: '0 0 15px' }}>
                                    {account.icon}
                                </div>
                                <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <span>{account.label}</span>
                                    {account.button || (
                                        <span style={{ color: account.statusColor }}>{account.status}</span>
                                    )}
                                </div>
                            </div>
                        ))}
                    </Checkbox.Group>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ flex: '0 0 30px', marginRight: '10px' }}>

                        </div>
                        <Button type="outline" size='mini' icon={<IconPlus />} onClick={addAccounts}>
                            添加账号
                        </Button>
                    </div>

                    {qrCode !== null
                        && <div>
                            <h3>使用微信扫码登录帐号接入</h3>
                            <QRCodeSVG
                                value={qrCode}  // 生成二维码的内容
                                size={150} />
                        </div>
                    }
                </div>
            </Modal>
        </div>
    );
};

export default LaunchModal;