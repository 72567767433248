import React, {useEffect, useState, useRef} from 'react';
import {
    Input,
    Button,
    Avatar,
    Typography,
    Tooltip,
    Space,
    Empty,
    Divider, Upload
} from '@arco-design/web-react';
import {
    IconFolderAdd,
    IconSend,
    IconWechat,
} from '@arco-design/web-react/icon';
import {sendMessageApi} from '../../../api/normalApi';
import {modifyMessageReadApi} from "../../../api/openapi";

const {Text} = Typography;
const UserChat = ({threadId, currentWxUser, currentWxMyAccount, messages, setMessages, imageUrls, getMessageList}) => {
    const [inputValue, setInputValue] = useState('');
    const contentRef = useRef(null);
    const [isUserScrolling, setIsUserScrolling] = useState(true);//是否允许气泡content滑动
    const disableValue = useRef(false)//是否允许输入框输入

    useEffect(() => {
        //获取当前的线程信息
        if (threadId) {
            getMessageList(threadId)
        } else {
            setMessages([]);
        }

        //控制对话气泡的滚动
        const handleScroll = () => {
            if (contentRef.current) {
                const {scrollTop, scrollHeight, clientHeight} = contentRef.current;
                const isAtBottom = scrollHeight - scrollTop === clientHeight;
                setIsUserScrolling(!isAtBottom);
            }
        };
        const currentRef = contentRef.current;
        if (currentRef) {
            currentRef.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (currentRef) {
                currentRef.removeEventListener('scroll', handleScroll);
            }
        };
        // eslint-disable-next-line
    }, [threadId]);


    useEffect(() => {
        //控制气泡出现是否滑动
        contentRef.current.scrollTo({
            top: contentRef.current.scrollHeight,
            behavior: 'smooth'
        });
        //判断传过来的消息是否是最新的
        if (messages && messages.length > 0) {
            modifyMessageRead()
        }
    }, [messages, isUserScrolling]);


    const modifyMessageRead = async () => {

            const data = {
                metadata: {
                    "readTime": String(Math.floor(Date.now() / 1000))
                }
            }
            await modifyMessageReadApi(messages[0].thread_id, messages[0].id, data).then(data => {
                // console.log(data);
            })

    }


    const handleSend = async () => {
        const back_data = {
            "wx_user_id": currentWxMyAccount.wx_user_id,
            "to_user_id": currentWxUser.current.to_user_id,
            msg: inputValue
        }
        setInputValue('')
        sendMessageApi(back_data).then(data => {
            if (data.status === 200) {
                getMessageList(threadId)
            }
        })
    };

    // 时间格式化
    const formatTimestampToTime = (timestamp) => {//格式化时间戳
        // 将 UNIX 时间戳转换为毫秒，并创建 Date 对象
        const date = new Date(timestamp * 1000);
        // 获取时分秒，并确保它们是两位数格式
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    }


    return (
        <Space direction="vertical"
               style={{width: "40vw", height: "100vh", boxShadow: '0px 0 6px rgba(0, 0, 0, 0.08)'}}
               align={"start"}>
            <Space style={{boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.03)', width: "40vw", height: 60}} align={"center"}>
                <Avatar size={35} style={{marginLeft: 15, marginRight: 5}}>
                    <img alt='avatar'
                         src={`data:image/png;base64,${currentWxUser.current ? currentWxUser.current.head_img : ''}`}/>
                </Avatar>
                <h3>{currentWxUser.current ? currentWxUser.current.nickname : ''}</h3>
                <Divider type='vertical'/>
                <IconWechat style={{fontSize: 18, color: '#07c160', marginRight: 10, marginTop: 5}} />
            </Space>
            <Space direction="vertical"
                   style={{
                       height: "80vh",
                       overflowY: 'auto',
                       width: "39vw",
                       scrollbarWidth: 'thin',
                       paddingLeft: 15,
                       paddingTop: 10,
                       scrollbarColor: '#EEE white'
                   }} ref={contentRef}>

                {messages.length === 0 ? <Empty description='暂无聊天数据' style={{
                    marginTop: "30vh",
                    width: "100%"
                }}/> : messages.slice().reverse().map((msg, index) => {
                    return (
                        <Space key={index} align={"start"}>
                            <Avatar>
                                <img alt='avatar'
                                     src={msg.role === "user" ? `data:image/png;base64,${currentWxUser.current ? currentWxUser.current.head_img : ''}` :
                                         `data:image/png;base64,${currentWxMyAccount.head_img}`
                                     }/>
                            </Avatar>
                            <Space direction={"vertical"}>
                                <Space direction='horizontal'>
                                    <Text>{msg.role === "user" ? (currentWxUser.current ? currentWxUser.current.nickname : '') : currentWxMyAccount.nickname}</Text>
                                    <Text type="secondary" style={{fontSize: '12px'}}>
                                        {formatTimestampToTime(msg.created_at)}
                                    </Text>
                                </Space>
                                <Space direction={"vertical"}>
                                    {msg.content.map((item, contentIndex) => {
                                        if (item.type === "text" && item.text && item.text.value) {
                                            return (
                                                <React.Fragment key={`${msg.id}-${contentIndex}`}>
                                                    {item.text.value.split(/(?<=[？?!。])/).map((sentence, sentenceIndex) => (
                                                        <Space key={`${msg.id}-${contentIndex}-${sentenceIndex}`}
                                                               align={"center"}>
                                                            <Text
                                                                style={{
                                                                    background: msg.role === "user" ? "#366ef4" : "#f7f7fa",
                                                                    color: msg.role === "user" ? "#000000" : "#ffffff",
                                                                    padding: "10px",
                                                                    borderRadius: "8px",
                                                                    backgroundColor: msg.isTyping
                                                                        ? "#65B0F4"
                                                                        : msg.role === "user"
                                                                            ? "#F7F7FA"
                                                                            : "#366EF4",
                                                                    display: "inline-block",
                                                                    width: "auto",
                                                                }}
                                                            >
                                                                {sentence.replace(/[？?！!。，,；;、]+$/g, "")}
                                                            </Text>
                                                        </Space>
                                                    ))}
                                                </React.Fragment>
                                            );
                                        } else if (item.type === "image_file" && imageUrls[item.image_file.file_id]) {
                                            return (
                                                <Space key={item.image_file.file_id}>
                                                    <img
                                                        src={imageUrls[item.image_file.file_id]}
                                                        alt="用户图片"
                                                        style={{width: '250px', height: 'auto'}}/>
                                                </Space>
                                            );
                                        }
                                        return null;
                                    })}
                                </Space>
                            </Space>
                        </Space>
                    )
                })}
            </Space>
            <Space style={{paddingLeft: 23, marginTop: 20, width: "100%"}}>
                <Tooltip content="选择文件发送">
                    <Upload
                        action={null}
                        showUploadList={false}
                        // fileList={pic ? [pic] : []}
                        multiple={false}
                        // onChange={(_, currentFile) => handleUploadPic(_, currentFile)}
                        accept=".png,.jpeg,.jpg"
                    >
                        <IconFolderAdd style={{width: "20", height: "20"}}/>
                    </Upload>
                </Tooltip>
                <Input
                    disabled={disableValue.current}
                    value={inputValue}
                    onChange={(value) => setInputValue(value)}
                    onPressEnter={handleSend}
                    style={{borderRadius: '3vh', height: '5vh', width: "30vw", marginLeft: 10}}
                />
                <Tooltip content="发送消息">
                    <Button
                        type={inputValue === '' ? "text" : "text"}
                        icon={<IconSend style={{width: "20", height: "20", marginLeft: 10}}/>}
                        onClick={handleSend}
                        disabled={inputValue === ''}
                    />
                </Tooltip>
            </Space>
        </Space>
    );
};

export default UserChat;
