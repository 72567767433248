import { Layout } from '@arco-design/web-react';
import React from 'react';
import SalesBarChart from './ components/Calculate';
import RechargeTable from './ components/Balance';
export const DataSuspect = () => {
    return (
        <Layout>
            <Layout.Sider style={{ width: '40vw', height: '55vh' }}>
                <SalesBarChart />
            </Layout.Sider>
            <Layout.Content style={{ width: '30vw', margin: '0 10px' }}>
                <RechargeTable />
            </Layout.Content>
        </Layout>
    )
}
