import React, {useState, useEffect} from 'react';
import {Table, Input, Button, Message, Space} from '@arco-design/web-react';
import {addProductApi, updateProductApi, getProductsApi} from '../../../api/normalApi';

const ProductList = () => {
    const [productList, setProductList] = useState([]);
    const [editableRow, setEditableRow] = useState(null);
    const [isNew, setIsNew] = useState(false); // 用于判断是否是新增状态
    const [addDisabled, setAddDisabled] = useState(false); // 用于禁用“新增商品”按钮

    const getProducts = async () => {
        try {
            const {data} = await getProductsApi();
            const productsWithKey = data.map((item, index) => ({
                ...item,
                key: index + 1, // 用索引+1作为编号
                isNew: false,   // 标记已有商品为非新增状态
            }));
            setProductList(productsWithKey);
        } catch (error) {
            Message.error('获取商品列表失败，请稍后重试');
        }
    };

    // 获取商品列表数据
    useEffect(() => {
        getProducts();
    }, []);

    // 新增商品行
    const addNewProduct = () => {
        const newKey = productList.length + 1; // 新增商品的编号为当前列表长度+1
        const newProduct = {
            key: newKey,
            name: '',
            price: '',
            description: '',
            urls: '',
            isNew: true, // 新增商品标记为新增状态
        };
        setProductList([...productList, newProduct]);
        setEditableRow(newKey); // 设置新增行为可编辑
        setIsNew(true); // 标记为新增状态
        setAddDisabled(true); // 禁用“新增商品”按钮
    };

    const handleEdit = (key) => {
        setEditableRow(key);
        setIsNew(false); // 标记为非新增行
    };

    // 保存时的校验与 API 调用
    const handleSave = async (key) => {
        const product = productList.find((item) => item.key === key);
        if (!product.name || !product.price || !product.description || !product.urls) {
            Message.error('所有字段和链接都不能为空！');
            return; // 终止保存操作
        }

        try {
            const back_data = {
                "name": product.name,
                "price": Number(product.price),
                "urls": product.urls,
                "description": product.description,
            }
            if (product.isNew) {
                // 新增商品
                await addProductApi(back_data).then(async data => {
                    if (data.status === "success") {
                        await getProducts();
                        Message.success('新增商品成功');
                    }
                });
            } else {
                // 编辑商品
                await updateProductApi(product.id, back_data).then(async data => {
                    if (data.status === "success") {
                        await getProducts();
                        Message.success('修改商品成功');
                    }
                });
            }

            // 更新保存后的商品，移除 isNew 标志
            const updatedProductList = productList.map((item) =>
                item.key === key ? {...item, isNew: false} : item
            );
            setProductList(updatedProductList);
            setEditableRow(null); // 清除编辑状态
            setAddDisabled(false); // 重新启用“新增商品”按钮
        } catch (error) {
            Message.error('操作失败，请稍后再试');
        }
    };

    const columns = [
        {
            title: '编号',
            dataIndex: 'key',
            width: 70,
        },
        {
            title: '商品/服务名称',
            dataIndex: 'name',
            // width: 180,
            render: (text, record) => {
                return editableRow === record.key ? (
                    <Input
                        value={record.name}
                        onChange={(value) => handleFieldChange(value, record.key, 'name')}
                    />
                ) : (
                    text
                );
            },
        },
        {
            title: '价格',
            dataIndex: 'price',
            render: (text, record) => {
                return editableRow === record.key ? (
                    <Input
                        value={record.price}
                        onChange={(value) => handleFieldChange(value, record.key, 'price')}
                    />
                ) : (
                    text
                );
            },
        },
        {
            title: '商品介绍',
            dataIndex: 'description',
            render: (text, record) => {
                return editableRow === record.key ? (
                    <Input
                        value={record.description}
                        onChange={(value) =>
                            handleFieldChange(value, record.key, 'description')
                        }
                    />
                ) : (
                    text
                );
            },
        },
        {
            title: '访问链接',
            dataIndex: 'urls',
            render: (text, record) => {
                return editableRow === record.key ? (
                    <Input
                        value={record.urls}
                        onChange={(value) => handleFieldChange(value, record.key, 'urls')}
                    />
                ) : (
                    text
                );
            },
        },
        {
            title: '操作',
            dataIndex: 'actions',
            width: 80,
            render: (_, record) => {
                return editableRow === record.key ? (
                    <Button onClick={() => handleSave(record.key)}>
                        {record.isNew ? '确认新增' : '保存'}
                    </Button>
                ) : (
                    <Button onClick={() => handleEdit(record.key)}>编辑</Button>
                );
            },
        },
    ];

    const handleFieldChange = (value, key, field) => {
        const newData = productList.map((item) => {
            if (item.key === key) {
                return {...item, [field]: value};
            }
            return item;
        });
        setProductList(newData);
    };

    return (
        <div>
            <h3>商品列表</h3>
            <Space direction={'vertical'}>
                <Table columns={columns} data={productList} pagination={false} style={{minWidth:"60vw"}}/>
                <Button type="primary" style={{marginTop: 10}} onClick={addNewProduct}>
                    新建商品
                </Button>
            </Space>
        </div>
    );
};

export default ProductList;
