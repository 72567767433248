import React, {useEffect, useRef, useState} from 'react';
import ChatList from './Messages/ChatList';
import {Space, Tabs, Tag} from '@arco-design/web-react';
import ProductList from '../AgentList/FinetuneEmployee/components/RoleConfig/ProductList';
import UserChat from './Messages/UserChat';
import {getWxAccountsApi, getWxUsersApi} from "../../api/normalApi";
import {getFileContentApi, getThreadMessages} from "../../api/openapi";

const Messages = () => {
    //user用户，my客服
    const [threadId, setThreadId] = useState("");//当前聊天
    const [wxMyAccountList, setWxMyAccountList] = useState([])//获取一次所有微信助手
    const [currentWxMyAccount, setCurrentWxMyAccount] = useState(null)//当前选中的小助手
    const currentWxUser=useRef(null)//当前选中的用户
    const [selectedWxMyTab, setSelectedWxMyTab] = useState([])//当前选中小助手下的微信用户
    const wxUserMessageList=useRef([])//对话最后一条的消息列表
    const wxUserUnreadList = useRef([])//用户未读消息数量

    const [messages, setMessages] = useState([]);
    const [imageUrls, setImageUrls] = useState({}); // 存储图片的URL

    useEffect(() => {
        getWxAccountsApi().then(response => {
            if (response.status === '200') {
                setWxMyAccountList(response.data); // 保存获取到的微信账号数据
                if (response.data.length > 0) {
                    setCurrentWxMyAccount(response.data[0]);// 默认选中第一个 Tab
                }
            }
        }).catch(error => {
            console.log("API Error:", error);
        });
        // eslint-disable-next-line
    }, []);

    const findFirstReadTimeMessage=(messages)=> {
        for (let i = 0; i < messages.length; i++) {
            if (messages[i].metadata && messages[i].metadata.readTime) {
                return i;  // 返回第一个匹配的索引
            }
        }
        return -1;  // 如果没有找到，返回 -1
    }

    // useEffect(() => {
    //    console.log(messages,findFirstReadTimeMessage(messages))
    // }, [messages]);

    useEffect(() => {
        if (!currentWxMyAccount) return;
        // 立即请求一次数据
        fetchWxUsers();
        const intervalId = setInterval(fetchWxUsers, 5000);

        // 清除定时器
        return () => clearInterval(intervalId);
        // eslint-disable-next-line
    }, [currentWxMyAccount, selectedWxMyTab.length]);

    const fetchWxUsers = async () => {
        if (currentWxMyAccount) {
            try {
                const userData = await getWxUsersApi(String(currentWxMyAccount.wx_user_id), false);
                if (userData.status === 200) {
                    // 仅当 selectedWxMyTab 的长度或内容发生变化时才更新(与当前数据和现在不同）
                    if (userData.data.length !== selectedWxMyTab.length || userData.data !== selectedWxMyTab) {
                        // 拿到头像
                        const userData = await getWxUsersApi(String(currentWxMyAccount.wx_user_id), true);
                        setSelectedWxMyTab(userData.data); // 更新 Tab 数据

                    }
                    let messageList = [];
                    let unreadList=[]
                    for (let user of userData.data) {
                        let messageDetail = await getMessageList(user.thread_id); // 请求消息列表
                        messageList.push(messageDetail.message);
                        unreadList.push(messageDetail.index);
                    }
                    if(JSON.stringify(wxUserMessageList.current) !== JSON.stringify(messageList)){//比较数组内容
                        wxUserMessageList.current=messageList
                    }
                    if(JSON.stringify(wxUserUnreadList.current) !== JSON.stringify(unreadList)){
                        wxUserUnreadList.current=unreadList
                    }
                }
                else if (userData.status === 404) {//数据为空的处理
                    setSelectedWxMyTab([])
                }
            } catch (error) {
                console.log("API Error:", error);
            }
        }
    };

    const splitAndLogSentences = (text) => {//分句函数
        return text.split(/(?<=[。？！])/).map(sentence => {
            sentence = sentence.trim();
            // 如果句子以"。"结尾，则去掉末尾的"。"
            if (sentence.endsWith('。')) {
                sentence = sentence.slice(0, -1);
            }
            return sentence;
        }).filter(sentence => sentence);
    };

    const getMessageList = async (threadId) => {//获取所有消息
        try {
            const data = await getThreadMessages(threadId);
            const messages = data.data;
            const firstReadTimeMessageIndex = findFirstReadTimeMessage(messages);

            //判断当前有没有选中的用户
            if (currentWxUser.current&&threadId===currentWxUser.current.thread_id) {
                messages.map(async (msg) => {
                    msg.content.map(async (item) => {
                        if (item.type === "image_file") {
                            const blob = await getFileContentApi(item.image_file.file_id);
                            const imageUrl = URL.createObjectURL(blob);
                            // 更新图片的URL到状态中
                            setImageUrls(prev => ({...prev, [item.image_file.file_id]: imageUrl}));
                        }
                    });
                });
                setMessages(messages); // 更新消息列表
            }

            //更新选中tab的message值
            if (messages.length > 0 && messages[0].content[0].text.value) {
                let item = messages[0];
                if (item.role === 'assistant') {
                    const splitMessages = splitAndLogSentences(item.content[0].text.value);
                    return {
                        message: splitMessages[splitMessages.length - 1],
                        index: firstReadTimeMessageIndex
                    };
                } else {
                    return {
                        message: item.content[0].text.value,
                        index: firstReadTimeMessageIndex
                    };
                }
            }
        } catch (error) {
            console.log("Error fetching messages:", error);
        }
    };


    return (
        <Space align={"start"}>
            <ChatList wxMyAccountList={wxMyAccountList}
                      currentWxAccount={currentWxMyAccount} setCurrentWxAccount={setCurrentWxMyAccount}
                      currentWxUser={currentWxUser}
                      threadId={threadId} setThreadId={setThreadId}
                      selectedWxMyTab={selectedWxMyTab}
                      wxUserMessageList={wxUserMessageList.current} wxUserUnreadList={wxUserUnreadList.current}/>
            <UserChat threadId={threadId} currentWxUser={currentWxUser} currentWxMyAccount={currentWxMyAccount}
                      messages={messages} setMessages={setMessages} imageUrls={imageUrls}
                      getMessageList={getMessageList}/>
            <Space direction={"vertical"}>
                <Space align={"center"} style={{width: "96%", marginLeft: 5, marginTop: 15}}>
                    <h3 style={{marginTop: 0, marginBottom: 0, marginLeft: 5}}>
                        小希老师
                    </h3>
                    <Tag color={"green"}>
                        正在服务
                    </Tag>
                    今日接待：59 人
                </Space>
                <Tabs defaultActiveTab='1' style={{marginTop: 8, marginLeft: 8}} type={"card-gutter"}>
                    <Tabs.TabPane key='1' title='商品列表' style={{marginLeft: 10, marginRight: 10}}>
                        <ProductList/>
                    </Tabs.TabPane>
                    <Tabs.TabPane key='2' title='订单列表'>
                        {/*<Typography.Paragraph style={style}>Content of Tab Panel 2</Typography.Paragraph>*/}
                    </Tabs.TabPane>
                    <Tabs.TabPane key='3' title='用户画像'>
                        {/*<Typography.Paragraph style={style}>Content of Tab Panel 3</Typography.Paragraph>*/}
                    </Tabs.TabPane>
                    <Tabs.TabPane key='4' title='销售流程'>
                        {/*<Typography.Paragraph style={style}>Content of Tab Panel 3</Typography.Paragraph>*/}
                    </Tabs.TabPane>
                </Tabs>
            </Space>
        </Space>

    )
}
export default Messages;