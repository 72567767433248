import React, { useState, useEffect } from 'react';
import {Avatar, Button, Dropdown, Layout, Menu, Popover, Space} from '@arco-design/web-react';
import { IconBook, IconCaretLeft, IconCaretRight, IconMessage, IconUserGroup } from '@arco-design/web-react/icon';
import Messages from "../MessageList/Messages";
import EditAgent from "../AgentList/EditAgent";
import SalesMaterial from "../SalesMaterial/SalesMaterial";
import { PeopleMarket } from "../PeopleMarket/PeopleMarket";
import { DataSuspect } from "../DataSuspect/DataSuspect";

const Content = Layout.Content;

const Home = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [selectedMenuKey, setSelectedMenuKey] = useState('employeeList');
    const [username, setUsername] = useState('');

    useEffect(() => {
        // 从本地存储获取 username
        const storedUsername = localStorage.getItem('user_name');
        if (storedUsername) {
            setUsername(storedUsername);
        }
    }, []);

    const handleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const handleMenuItemClick = (key) => {
        setSelectedMenuKey(key);
    };

    const handleLogout = () => {
        // 清除本地存储的 token
        localStorage.removeItem('sales_token');
        // 跳转到登录页
        window.location.href = '/login';
    };

    const renderContent = () => {
        switch (selectedMenuKey) {
            case 'messages':
                return <Messages />;
            case 'employeeList':
                return <EditAgent />;
            case 'salesMaterial':
                return <SalesMaterial />;
            case 'market':
                return <PeopleMarket />;
            case 'dataSuspect':
                return <DataSuspect />;
            default:
                return <Messages />;
        }
    };

    const PlatformList = ({ onMenuItemClick }) => {
        return (
            <Menu
                defaultSelectedKeys={[selectedMenuKey]}
                onClickMenuItem={(key) => {
                    onMenuItemClick(key);
                }}
            >
                <Menu.Item key="messages">
                    <IconMessage />
                    消息列表
                </Menu.Item>
                <Menu.Item key="employeeList">
                    <IconUserGroup />
                    员工列表
                </Menu.Item>
                {/*<Menu.Item key="market">*/}
                {/*    <IconUser />*/}
                {/*    人才市场*/}
                {/*</Menu.Item>*/}
                <Menu.Item key="salesMaterial">
                    <IconBook />
                    销售资料
                </Menu.Item>
                {/*<Menu.Item key="dataSuspect">*/}
                {/*    <IconDashboard />*/}
                {/*    数据监控*/}
                {/*</Menu.Item>*/}
            </Menu>
        );
    };

    return (
        <Layout style={{ height: "100vh", width: "100%" }}>
            <Layout.Sider collapsed={collapsed} onCollapse={handleCollapsed} collapsible style={{ height: "100vh" }}
                          width={'150px'}
                          trigger={collapsed ? <IconCaretRight /> : <IconCaretLeft />}>
                <Space size='large' style={{ height: 48, marginTop: 7 }}>
                    <img src={`${process.env.PUBLIC_URL}/logo.svg`} alt="logo" style={{ marginLeft: 12 }} />
                </Space>
                <PlatformList onMenuItemClick={handleMenuItemClick} />
                <Space style={{ marginTop: "69vh",width:"100%"}} direction={"vertical"} align={"center"}>
                    <Popover position='right' content={ <Button type={"outline"} onClick={handleLogout}>
                        退出登录
                    </Button>}>
                        <Avatar style={{ backgroundColor: '#3370ff'}} size={35}>
                            {username ? username : ''}
                        </Avatar>
                    </Popover>
                </Space>
            </Layout.Sider>
            <Content>
                {renderContent()}
            </Content>
        </Layout>
    );
}

export default Home;