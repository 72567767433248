import React from 'react';
import {Button, Collapse, Input} from '@arco-design/web-react';

const CollapseItem = Collapse.Item;
const {TextArea} = Input;

function RoleInfo({setChatInstruction, chatInstruction}) {
    return (
        <Collapse defaultActiveKey="1">
            <CollapseItem header="角色信息" name="1" extra={<Button type='text'>优化</Button>}>
                <TextArea
                    autoSize={{minRows: 5, maxRows: 10}}
                    value={chatInstruction}
                    onChange={(v) => {
                        setChatInstruction(v);}
                    }
                />
            </CollapseItem>
        </Collapse>
    );
}

export default RoleInfo;
