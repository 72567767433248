import React, {useState, useRef, useEffect} from 'react';
import {Table, Input} from '@arco-design/web-react';

function EditableCell({editable, children, rowData, dataIndex, onSave}) {
    const [editing, setEditing] = useState(false);
    const [value, setValue] = useState(rowData[dataIndex]);
    const inputRef = useRef(null);

    const handleSave = () => {
        setEditing(false);
        onSave({...rowData, [dataIndex]: value});
    };

    useEffect(() => {
        if (editing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [editing]);

    if (editable) {
        return editing ? (
            <Input
                ref={inputRef}
                value={value}
                onChange={setValue}
                onBlur={handleSave}
                onPressEnter={handleSave}
            />
        ) : (
            <div onDoubleClick={() => setEditing(true)}>
                {children}
            </div>
        );
    }

    return <div>{children}</div>;
}

function ProductList() {
    const [data, setData] = useState([
        {
            key: '1',
            name: 'IRY 分型肌肤护理套装（男士轻度）',
            price: '468',
            link: 'https://item.jd.com/10031921829471.html',
            description: '深层清洁，赶走油腻油光',
            case: 'https://example.com/1'
        },
        {
            key: '2',
            name: 'IRY 分型肌肤护理套装（女士轻度）',
            price: '468',
            link: 'https://item.jd.com/10036325085299.html',
            description: '深层清洁，赶走油腻油光',
            case: 'https://example.com/2'
        },
        {
            key: '3',
            name: 'IRY 分型肌肤护理套装（男士重度）',
            price: '518',
            link: 'https://item.jd.com/10040254171970.html',
            description: '深层清洁，赶走油腻油光',
            case: 'https://example.com/3'
        },
        {
            key: '4',
            name: 'IRY 分型肌肤护理套装（女士重度）',
            price: '518',
            link: 'https://item.jd.com/10073664585394.html',
            description: '深层清洁，赶走油腻油光',
            case: 'https://example.com/4'
        },
    ]);

    const handleSave = (row) => {
        const newData = [...data];
        const index = newData.findIndex((item) => row.key === item.key);
        newData[index] = row;
        setData(newData);
    };

    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            width: '30%',
            editable: true,
        },
        {
            title: '价格',
            dataIndex: 'price',
            editable: true,
            width: 60,
        },
        {
            title: '链接',
            dataIndex: 'link',
            render: (text) => <a href={text} target="_blank" rel="noopener noreferrer">查看</a>,
            width: 60,
        },
        {
            title: '介绍',
            dataIndex: 'description',
            editable: true,
        },
        {
            title: '案例',
            dataIndex: 'case',
            render: (text) => <a href={text} target="_blank" rel="noopener noreferrer">查看</a>,
            width: 60,
        },
    ];

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                onSave: handleSave,
            }),
        };
    });

    return (
        <Table
            data={data}
            columns={mergedColumns}
            components={{
                body: {
                    cell: EditableCell,
                },
            }}
            pagination={false}
        />
    );
}

export default ProductList;
