import React, {useEffect, useMemo, useRef} from 'react';
import {Tabs, List, Avatar, Space, Badge} from '@arco-design/web-react';

const TabPane = Tabs.TabPane;

const ChatList = ({
                      threadId,
                      setThreadId,
                      wxMyAccountList,
                      currentWxAccount,
                      setCurrentWxAccount,
                      currentWxUser,
                      selectedWxMyTab,
                      wxUserMessageList,
                      wxUserUnreadList
                  }) => {

    const selectedWxMyAccountId = useMemo(() => {
        if (currentWxAccount) {
            return currentWxAccount.wx_user_id
        }
    }, [currentWxAccount])

    const selectThreadId = useMemo(() => {
        if (currentWxUser&&currentWxUser.current) {
            return currentWxUser.current.thread_id
        }
    }, [currentWxUser.current])


    //选中默认的user对话列表
    const handleDefaultUser = (wxUserList) => {
        if (wxUserList.length > 0) {
            setThreadId(wxUserList[0].thread_id)
            currentWxUser.current=wxUserList[0]
        }
        else {
            setThreadId('')
            currentWxUser.current=null
        }
    }

    const currentThreadId=useRef('')

    useEffect(() => {
        currentThreadId.current = threadId
    }, [threadId]);

    //有变化默认选中第一个
    useEffect(() => {
        if(!currentThreadId.current) handleDefaultUser(selectedWxMyTab)
    }, [wxUserMessageList]);

    // 处理 Tab 切换时的操作
    const handleTabChange = (v) => {
        const foundAccount = wxMyAccountList.find(account => account.wx_user_id === Number(v));
        setCurrentWxAccount(foundAccount)
        setThreadId('')//设置为空聊天记录
    };

    // 处理 List.Item 点击时的操作
    const handleItemClick = (item, threadId) => {
        // setCurrentWxUser(item)
        setThreadId(threadId); // 向父组件传递 thread_id
        currentWxUser.current=item
    };


    return (
        <div style={{width: "20vw", marginTop: 12}}>
            <Tabs
                activeTab={String(selectedWxMyAccountId)} // 改为 activeTab 来动态绑定当前选中的 wx_user_id
                onChange={handleTabChange}
                type={"text"}
            >
                {wxMyAccountList.map(account => (
                    <TabPane
                        key={account.wx_user_id}
                        title={<Space>
                            <Avatar shape="circle" size={30}>
                                <img alt={'头像'} src={`data:image/png;base64,${account.head_img}`}/>
                            </Avatar>
                            {account.wx_nickname}
                        </Space>}
                        style={{paddingLeft: 15, paddingRight: 8}}
                    >
                        {selectedWxMyTab && selectedWxMyTab.length > 0 ? ( // 确保 currentTabData 有内容时渲染 List
                            <List
                                dataSource={selectedWxMyTab}
                                render={(item, index) => (
                                    <List.Item
                                        key={index}
                                        onClick={() => handleItemClick(item, item.thread_id)} // 点击时设置选中状态
                                        style={{
                                            backgroundColor: item.thread_id === selectThreadId ? 'rgba(54, 110, 244, 0.05)' : '', // 选中时的背景色，透明度0.1
                                            cursor: 'pointer' // 鼠标悬停样式
                                        }}
                                    >
                                        <Space direction={"vertical"}>
                                            <Space>
                                                <Badge count={wxUserUnreadList[index]} dotStyle={{margin: '5px'}} offset={[2, 8]}>
                                                <Avatar shape="circle">
                                                    <img alt={'头像'}
                                                         src={`data:image/png;base64,${item.head_img}`}/>
                                                </Avatar>
                                                </Badge>
                                                {item.nickname}
                                            </Space>
                                            <Space style={{padding: 5}}>
                                                {wxUserMessageList[index]}
                                            </Space>
                                        </Space>
                                    </List.Item>
                                )}
                            />
                        ) : (
                            <div>暂无聊天数据</div> // 当 currentTabData 为空时显示提示
                        )}
                    </TabPane>
                ))}
            </Tabs>
        </div>
    );
};

export default ChatList;
