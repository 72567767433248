import React from 'react';
import { Button, Collapse, List } from '@arco-design/web-react';

const CollapseItem = Collapse.Item;
function SalesProcess() {
    const data = [
        {
            title: '痛点命中',
            description: '推出引导性的问题，引发用户思考自己的皮肤问题。',
        },
        {
            title: '信息收集',
            description: '获取用户的具体皮肤状态，以便后续推荐产品。',
        },
        {
            title: '提出方案',
            description: '基于用户分享的个人情况，提出可行的护肤方案。',
        },
        {
            title: '回答疑惑',
            description: '回答用户关于产品成分、使用方法的疑虑，增加信任感。',
        },
        {
            title: '促成交易',
            description: '提供购买优惠或建议，鼓励用户下单。',
        },
    ];

    return (
        <Collapse defaultActiveKey={['1']}>
            <CollapseItem header="销售流程" key="1" extra={<Button type={"text"}>查看</Button>}>
                <List
                    bordered
                    dataSource={data}
                    render={(item, index) => (
                        <List.Item key={index}>
                            <List.Item.Meta
                                title={<span style={{ color: '#3c70ff' }}>{item.title}</span>}
                                description={item.description}
                            />
                        </List.Item>
                    )}
                />
            </CollapseItem>
        </Collapse>
    );
}

export default SalesProcess;
