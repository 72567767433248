import React, { useState, useEffect } from 'react';
import { Table, Button, Input, Upload, Message } from '@arco-design/web-react';
import { IconUpload } from '@arco-design/web-react/icon';
import { addCaseApi, getCasesApi, updateCaseApi } from '../../../api/normalApi'; // 引入API

const CaseMaterial = () => {
    const [caseList, setCaseList] = useState([]);
    const [editableRow, setEditableRow] = useState(null);
    const [isNewRow, setIsNewRow] = useState(false); // 标记是否为新增行
    const [addDisabled, setAddDisabled] = useState(false); // 控制“新建资料”按钮的禁用

    const getCases = async () => {
        try {
            const { data } = await getCasesApi(); // 从后端获取案例数据
            const casesWithKey = data.map((item, index) => ({
                ...item,
                key: index + 1, // 给每条数据一个唯一键值
                isNew: false,   // 标记已有案例为非新增状态
            }));
            setCaseList(casesWithKey);
        } catch (error) {
            Message.error('获取案例列表失败，请稍后重试');
        }
    };

    // 获取案例数据
    useEffect(() => {
        getCases();
    }, []);

    // 新增案例行
    const addNewCase = () => {
        const newKey = caseList.length + 1; // 新增案例的编号为当前列表长度+1
        const newCase = {
            key: newKey,
            case_name: '',
            file_url: '',
            description: '',
            openai_file_id: '',
            isNew: true, // 标记为新增案例
        };
        setCaseList([...caseList, newCase]);
        setEditableRow(newKey); // 设置新增行为可编辑
        setIsNewRow(true); // 标记为新增行
        setAddDisabled(true); // 禁用“新建资料”按钮
    };

    // 编辑行
    const handleEdit = (key) => {
        setEditableRow(key);
        setIsNewRow(false); // 标记为非新增行
    };

    // 保存行（新增或更新）
    const handleSave = async (key) => {
        const caseData = caseList.find((item) => item.key === key);
        if (!caseData.case_name || !caseData.description) {
            Message.error('案例名称和描述不能为空！');
            return;
        }

        const back_data = {
            "case_name": caseData.case_name,
            "file_url": caseData.file_url,
            "description": caseData.description,
            "openai_file_id": caseData.openai_file_id,
        };

        try {
            if (caseData.isNew) {
                // 新增案例
                await addCaseApi(back_data).then(async data=>{
                    if(data.status === "success") {
                        await getCases()
                        Message.success('新增案例成功');
                    }
                });
            } else {
                // 编辑案例
                await updateCaseApi(caseData.id, back_data).then(async data=>{
                    if(data.status === "success") {
                        await getCases()
                        Message.success('修改案例成功');
                    }
                });
            }

            // 更新保存后的案例，移除 isNew 标志
            const updatedCaseList = caseList.map((item) =>
                item.key === key ? { ...item, isNew: false } : item
            );
            setCaseList(updatedCaseList);
            setEditableRow(null); // 清除编辑状态
            setAddDisabled(false); // 重新启用“新建资料”按钮
        } catch (error) {
            Message.error('操作失败，请稍后再试');
        }
    };

    // 上传文件处理
    const handleUploadChange = (info, key) => {
        const { response } = info;
        if (response && response.url) {
            const newData = caseList.map((item) => {
                if (item.key === key) {
                    return { ...item, file_url: response.url };
                }
                return item;
            });
            setCaseList(newData);
        }
    };

    const columns = [
        {
            title: '编号',
            dataIndex: 'key',
            width: 70,
        },
        {
            title: '案例/资料名称',
            dataIndex: 'case_name',
            render: (text, record) => {
                return editableRow === record.key ? (
                    <Input
                        value={record.case_name}
                        onChange={(value) => handleFieldChange(value, record.key, 'case_name')}
                    />
                ) : (
                    text
                );
            },
        },
        {
            title: '案例描述',
            dataIndex: 'description',
            render: (text, record) => {
                return editableRow === record.key ? (
                    <Input
                        value={record.description}
                        onChange={(value) => handleFieldChange(value, record.key, 'description')}
                    />
                ) : (
                    text
                );
            },
        },
        {
            title: '案例文件',
            dataIndex: 'file_url',
            render: (text, record) => (
                <>
                    <a href={text} target="_blank" rel="noopener noreferrer">
                        {text || '暂无文件'}
                    </a>
                    {editableRow === record.key && (
                        <Upload
                            showUploadList={false}
                            action='/upload' // 替换为实际的上传 URL
                            onChange={(info) => handleUploadChange(info, record.key)}
                        >
                            <Button type="primary" icon={<IconUpload />} style={{ marginLeft: 8 }}>
                                {record.file_url ? '重新上传' : '上传'}
                            </Button>
                        </Upload>
                    )}
                </>
            ),
        },
        {
            title: '操作',
            dataIndex: 'actions',
            width: 80,
            render: (_, record) => {
                if (isNewRow && editableRow === record.key) {
                    return (
                        <Button type="primary" onClick={() => handleSave(record.key)}>
                            保存资料
                        </Button>
                    );
                } else {
                    return editableRow === record.key ? (
                        <Button type="primary" onClick={() => handleSave(record.key)}>
                            保存
                        </Button>
                    ) : (
                        <Button onClick={() => handleEdit(record.key)}>编辑</Button>
                    );
                }
            },
        },
    ];

    const handleFieldChange = (value, key, field) => {
        const newData = caseList.map((item) => {
            if (item.key === key) {
                return { ...item, [field]: value };
            }
            return item;
        });
        setCaseList(newData);
    };

    return (
        <div style={{ marginTop: 25 }}>
            <h3>案例资料</h3>
            <Table
                columns={columns}
                data={caseList}
                pagination={false}
                rowKey="key"
                style={{minWidth:"60vw"}}
            />
            <Button type="primary" onClick={addNewCase} style={{ marginTop: 16 }} disabled={addDisabled}>
                新建资料
            </Button>
        </div>
    );
};

export default CaseMaterial;
