// src/AppRouter.js
import React from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import Login from "./pages/Layout/Login";
import Home from "./pages/Layout/Home";

const PrivateRoute = ({component: Component, ...rest}) => {
    const token = localStorage.getItem('token');
    return token ? <Component {...rest} /> : <Navigate to="/login"/>;
};

function AppRouter() {
    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login/>}/>
                <Route path="/home" element={<PrivateRoute component={Home}/>}/>
                <Route path="/" element={<Navigate to="/home"/>}/>
            </Routes>
        </Router>
    );
}

export default AppRouter;
