import {Space, Tabs} from '@arco-design/web-react';
import React from 'react';
import ProductList from "./components/ProductTable";
import CaseMaterial from "./components/CaseMaterial";

const TabPane = Tabs.TabPane;

const SalesMaterial = () => {
    return (
        <Space direction={"vertical"} style={{paddingLeft:15}}>
            <ProductList />
            <CaseMaterial/>
        </Space>
    )
}
export default SalesMaterial;