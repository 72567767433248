import React, {useEffect, useRef, useState} from 'react';
import {
    Avatar,
    Button,
    Empty,
    Input, Message,
    Space,
    Tabs,
    Tooltip,
    Typography,
    Upload
} from '@arco-design/web-react';
import {IconRefresh, IconCopy, IconDelete, IconFileImage, IconSend} from '@arco-design/web-react/icon';
import {
    createThread,
    deleteMessageApi,
    getFileContentApi,
    getThreadMessages,
    postMessageByThreadBot, postRunByThreadBot, uploadFileAPi
} from '../../../../api/openapi';
import LaunchModal from './LaunchModal';

const {Text} = Typography;
const TabPane = Tabs.TabPane;

const OpenaiChatInterface = ({chatInstruction}) => {
    const [threadId, setThreadId] = useState("");
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [replyMode, setReplyMode] = useState("quick");
    const [imageUrls, setImageUrls] = useState({}); // 存储图片的URL
    const [file, setFile] = React.useState();
    const [isUserScrolling, setIsUserScrolling] = useState(false);//是否允许气泡content滑动
    const contentRef = useRef(null);
    const disableValue = useRef(false)//是否允许输入框输入


    // 初始化消息
    useEffect(() => {
        // 获取本地存储的回复模式
        let mode = JSON.parse(localStorage.getItem('reply_mode'))
        if (mode) {
            setReplyMode(mode)
        } else {
            setReplyMode("quick");
        }

        let thread = JSON.parse(localStorage.getItem('thread_id'))
        if (thread) {
            setThreadId(thread);
            getMessageList(JSON.parse(localStorage.getItem('thread_id'))).then(r => {
            })
        } else {
            setMessages([]);
            newThreadHandler().then(r => {
            });
        }

        //控制对话气泡的滚动
        const handleScroll = () => {
            if (contentRef.current) {
                const {scrollTop, scrollHeight, clientHeight} = contentRef.current;
                const isAtBottom = scrollHeight - scrollTop === clientHeight;
                setIsUserScrolling(!isAtBottom);
            }
        };
        const currentRef = contentRef.current;
        if (currentRef) {
            currentRef.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (currentRef) {
                currentRef.removeEventListener('scroll', handleScroll);
            }
        };
        // eslint-disable-next-line
    }, []);

    //控制气泡出现是否滑动
    useEffect(() => {
        if (!isUserScrolling && contentRef.current) {
            contentRef.current.scrollTo({
                top: contentRef.current.scrollHeight,
                behavior: 'smooth'
            });
        }
    }, [messages, isUserScrolling]);


    // 更改回复模式
    const changeReplyMode = (mode) => {
        setReplyMode(mode);
        localStorage.setItem('reply_mode', JSON.stringify(mode));
    }

    // 时间格式化
    const formatTimestampToTime = (timestamp) => {//格式化时间戳
        // 将 UNIX 时间戳转换为毫秒，并创建 Date 对象
        const date = new Date(timestamp * 1000);
        // 获取时分秒，并确保它们是两位数格式
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    }


    // 获取消息列表
    const getMessageList = async (threadId) => {
        const data = await getThreadMessages(threadId);
        const messages = data.data;

        // 获取图片内容并生成URL
        messages.map(async (msg) => {
            msg.content.map(async (item) => {
                if (item.type === "image_file") {
                    const blob = await getFileContentApi(item.image_file.file_id);
                    const imageUrl = URL.createObjectURL(blob);
                    // 更新图片的URL到状态中
                    setImageUrls(prev => ({...prev, [item.image_file.file_id]: imageUrl}));
                }
            });
        });

        setMessages(messages); // 更新消息列表
    };

    // 渲染最后一句回复
    const getMessageReply = async (threadId) => {
        if (replyMode === "quick") {
            getMessageList(threadId)
        } else {
            const data = await getThreadMessages(threadId);
            const messages = data.data;

            // 获取最后一条消息
            const lastMessage = messages[0];  // 获取第一条消息

            if (lastMessage.content[0].type === "text") {
                // 按句号分割成多句话
                const sentences = lastMessage.content[0].text.value.split('。').filter(sentence => sentence.trim().length > 0);

                let accumulatedText = '';  // 初始化累积的文本内容

                // 遍历句子，并逐步更新消息内容
                sentences.forEach((sentence, index) => {
                    let delay = sentence.length * 250;
                    if (index === 0) {
                        delay = 0
                    }
                    setTimeout(() => {
                        // 累积当前句子
                        accumulatedText += sentence + "。";

                        // 创建 messages 的副本，保持不变性
                        const updatedMessages = [...messages];
                        updatedMessages[0] = {
                            ...updatedMessages[0],
                            content: [
                                {
                                    ...updatedMessages[0].content[0],
                                    text: {
                                        ...updatedMessages[0].content[0].text,
                                        value: accumulatedText  // 设置为累积的文本内容
                                    }
                                }
                            ]
                        };
                        // 更新状态
                        setMessages(updatedMessages);
                    }, delay); // 每句话的加入间隔1秒
                });
            }
        }

    };


    //新建对话
    const newThreadHandler = async () => {
        const thread_id = await createThread().then(data => {
            if (data) {
                setThreadId(data.id)
                localStorage.removeItem("thread_id")
                localStorage.setItem("thread_id", JSON.stringify(data.id))
                setMessages([])
                // console.log('Final response:', data);
                return data.id
            }
        })
        await postMessageByThreadBot(thread_id, "assistant", "你好呀，需要解决痘痘还是痘印问题？")
        getMessageList(thread_id)
    }


    // 复制消息
    const handleCopy = (content) => {//复制当前内容
        navigator.clipboard.writeText(content)
    };

    // 删除消息
    const handleDeleteMessage = async (msg) => {//删除当前的一句对话
        setIsUserScrolling(true)
        deleteMessageApi(threadId, msg.id).then(data => {
            setMessages(prevMessages => prevMessages.filter(message => message.id !== msg.id));
        })
    }

    const handleRefreshMessage = async (msg, msgIndex) => {//刷新当前的回答
        setIsUserScrolling(true)
        const endIndex = messages.length - 1 - msgIndex;

        // 更新消息状态，过滤掉已经删除的消息
        setMessages(prevMessages => prevMessages.filter((message, index) => index > endIndex));

        // 生成删除消息的Promise数组
        const deletePromises = [];
        for (let i = 0; i <= endIndex; i++) {
            deletePromises.push(deleteMessageApi(threadId, messages[i].id));
        }

        // 等待所有删除操作完成
        await Promise.all(deletePromises);

        // 调用 postRunByThreadBot 和 getMessageReply
        postRunByThreadBot(threadId,chatInstruction).then(data => {
            getMessageReply(threadId);
        });
    };



    // 发送消息
    const handleSend = async () => {
        if(inputValue.trim() !== ''){
            setIsUserScrolling(false);
            // 禁用输入框，防止重复发送
            disableValue.current = true;

                // 发送用户消息
                await postMessageByThreadBot(threadId, "user", inputValue, null);


            // 刷新和执行消息
            await getMessageList(threadId);
            setInputValue('');
            disableValue.current = false;

            await postRunByThreadBot(threadId,chatInstruction);
            await getMessageReply(threadId);
        } else{
            Message.warning('请输入内容')
        }

        // if (inputValue.trim()) {
        //     // 发送用户消息
        //     await postMessageByThreadBot(threadId, "user", inputValue, null);
        // }
        //
        // // 刷新和执行消息
        // await getMessageList(threadId);
        // setInputValue('');
        // disableValue.current = false;
        //
        // await postRunByThreadBot(threadId,chatInstruction);
        // await getMessageReply(threadId);
    };

    // 上传图片
    const handleUploadPic = (currentFile) => {
        setFile({
            ...currentFile,
            url: URL.createObjectURL(currentFile.originFile),
        })

        if (currentFile.status === "uploading") {
            uploadFileAPi(currentFile.originFile).then(data => {
                postMessageByThreadBot(threadId, "user", inputValue, data).then(data => {
                    getMessageList(threadId).then(data => {
                        setFile(null)
                        postRunByThreadBot(threadId).then(data => {
                            getMessageReply(threadId);
                        })
                    });
                });
            });
        }

    };

    return (
        <Space direction="vertical"
               style={{width: "45vw", height: "100vh", boxShadow: '-2px 0 2px rgba(0, 0, 0, 0.05)', paddingLeft: 13}}>
            <Space style={{width: "100%", height: 40, marginTop: 8}}>
                <h3>聊天测试</h3>
                <Tabs type={"text"}
                      activeTab={replyMode}
                      onChange={changeReplyMode}
                >
                    <TabPane key="personal" title="拟人回复"/>
                    <TabPane key="quick" title="快速回复"/>
                </Tabs>
                <LaunchModal/>
            </Space>

            <Space direction='vertical' style={{width: "100%"}}>
                <Space direction="vertical"
                       style={{
                           height: "82vh",
                           overflowY: 'auto',
                           width: "100%",
                           scrollbarWidth: 'thin',
                           scrollbarColor: '#EEE white'
                       }} ref={contentRef}>
                    {messages.length === 0 ? <Empty description='暂无聊天数据' style={{
                        marginTop: "30vh",
                        width: "100%"
                    }}/> : messages.slice().reverse().map((msg, index) => {
                        return (
                            <Space key={index} align={"start"}>
                                <Avatar>
                                    <img alt='avatar'
                                         src={msg.role === "user" ? "https://pic1.zhimg.com/70/v2-53c1bc420d925bbc55d3ebddc1f1a091_1440w.avis?source=172ae18b&biz_tag=Post" :
                                             "https://pic1.zhimg.com/70/v2-73543239e7ae941adf7d3a4d62385951_1440w.avis?source=172ae18b&biz_tag=Post"
                                         }/>
                                </Avatar>
                                <Space direction={"vertical"}>
                                    <Space direction='horizontal'>
                                        <Text>{msg.role === "user" ? "用户" : "销售"}</Text>
                                        <Text type="secondary" style={{fontSize: '12px'}}>
                                            {formatTimestampToTime(msg.created_at)}
                                        </Text>
                                    </Space>
                                    <Space direction={"vertical"}>
                                        {msg.content.map((item, contentIndex) => {
                                            if (item.type === "text" && item.text && item.text.value) {
                                                return (
                                                    <React.Fragment key={`${msg.id}-${contentIndex}`}>
                                                        {item.text.value.split(/(?<=[?!。])/).map((sentence, sentenceIndex) => (
                                                            <Space key={`${msg.id}-${contentIndex}-${sentenceIndex}`}
                                                                   align={"center"}>
                                                                <Text
                                                                    style={{
                                                                        background: msg.role === "user" ? "#366ef4" : "#f7f7fa",
                                                                        color: msg.role === "user" ? "#000000" : "#ffffff",
                                                                        padding: "10px",
                                                                        borderRadius: "8px",
                                                                        backgroundColor: msg.isTyping
                                                                            ? "#65B0F4"
                                                                            : msg.role === "user"
                                                                                ? "#F7F7FA"
                                                                                : "#366EF4",
                                                                        display: "inline-block",
                                                                        width: "auto",
                                                                    }}
                                                                >
                                                                    {sentence.replace(/[？?！!。，,；;、]+$/g, "")}
                                                                </Text>
                                                                <Space>
                                                                    {msg.role !== "user" && (
                                                                        <Button size='mini' icon={<IconCopy/>}
                                                                                onClick={() => handleCopy(sentence.replace(/[？?！!。，,；;、]+$/g, ""))}
                                                                                type="text"
                                                                                style={{color: 'black'}}></Button>

                                                                    )}
                                                                    {/* 如果是最后一个句子且是助手的消息才显示按钮 */}
                                                                    {sentenceIndex === 0 && (
                                                                        <Space>
                                                                            <Button size='mini' icon={<IconDelete/>}
                                                                                    onClick={() => handleDeleteMessage(msg)}
                                                                                    type="text"
                                                                                    style={{color: 'black'}}></Button>
                                                                            {msg.role !== "user" && (
                                                                                <Tooltip content="重新回答">
                                                                                    <Button size='mini' icon={<IconRefresh/>}
                                                                                            onClick={() => handleRefreshMessage(msg,index)}
                                                                                            type="text"
                                                                                            style={{color: 'black'}}></Button>
                                                                                </Tooltip>

                                                                            )}
                                                                        </Space>
                                                                    )}
                                                                </Space>
                                                            </Space>
                                                        ))}
                                                    </React.Fragment>
                                                );
                                            } else if (item.type === "image_file" && imageUrls[item.image_file.file_id]) {
                                                return (
                                                    <Space key={item.image_file.file_id}>
                                                        <img
                                                            src={imageUrls[item.image_file.file_id]}
                                                            alt="用户图片"
                                                            style={{width: '250px', height: 'auto'}}/>
                                                        <Button size='mini' icon={<IconDelete/>}
                                                                onClick={() => handleDeleteMessage(msg)}
                                                                type="text"
                                                                style={{color: 'black'}}></Button>

                                                    </Space>
                                                );
                                            }
                                            return null;
                                        })}
                                    </Space>
                                </Space>
                            </Space>
                        )
                    })}
                </Space>


                <Space style={{width: "80%", marginTop: "2vh", paddingLeft: 4}}>
                    <Tooltip content="清空对话记录">
                        <Button type='text'
                                shape='circle'
                                onClick={newThreadHandler}
                                icon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                           viewBox="0 0 16 16" fill="currentColor">
                                    <path
                                        d="M2 6h4V2.333C6 1.597 6.597 1 7.334 1h1.333C9.403 1 10 1.597 10 2.333V6h4c.737 0 1.334.597 1.334 1.333V14c0 .736-.597 1.333-1.334 1.333H2A1.333 1.333 0 0 1 .667 14V7.333C.667 6.597 1.264 6 2 6Zm5.334-3.667v4a1 1 0 0 1-1 1H2v1.334h12V7.333H9.667a1 1 0 0 1-1-1v-4H7.334ZM2 10v4h2.667v-1.667a.667.667 0 0 1 1.333 0V14h1.334v-2a.667.667 0 1 1 1.333 0v2H10v-1.667a.667.667 0 0 1 1.334 0V14H14v-4H2Z"/>
                                </svg>}

                        />
                    </Tooltip>
                    <Input
                        style={{height: '5vh', minWidth: "35vw", marginLeft: 4}}
                        disabled={disableValue.current}
                        value={inputValue}
                        onChange={(value) => setInputValue(value)}
                        onPressEnter={handleSend}
                        suffix={
                            <Upload
                                showUploadList={false}
                                fileList={file ? [file] : []}
                                multiple={false}
                                onChange={(_, currentFile) => handleUploadPic(currentFile)}
                                accept=".png,.jpeg,.jpg"
                            >
                                <Button type="text" size={"mini"} icon={<IconFileImage/>} style={{color: 'black'}}>
                                </Button>
                            </Upload>
                            // <Popover
                            //          size="small"
                            //          content={
                            //              <div
                            //                  style={{height: 250, minWidth: 200, textAlign: "center", display: "flex"}}>
                            //                  <Space direction={"vertical"} style={{width: "100%"}} align={"center"}>
                            //                      {pic && pic.url ?
                            //                          <img
                            //                              src={pic.url}
                            //                              alt="预览图片"
                            //                              style={{width: 150}}
                            //                          /> :
                            //                          <Result
                            //                              title='暂无图片'
                            //                          ></Result>}
                            //                      <Button
                            //                          type={'text'}
                            //                          icon={<IconCloseCircle/>}
                            //                          style={{
                            //                              display: pic && pic.url ? 'inline' : 'none'
                            //                          }}
                            //                          shape="circle"
                            //                          onClick={() => setPic({})}
                            //                      />
                            //                  </Space>
                            //              </div>
                            //          }>
                            //
                            // </Popover>
                        }
                    />
                    <Tooltip content="发送聊天">
                        <Button
                            type={inputValue === '' ? "text" : "text"}
                            icon={<IconSend style={{width: "20", height: "20", marginLeft: 10, marginTop: 3}}/>}
                            onClick={handleSend}
                            disabled={inputValue === ''}
                        />
                    </Tooltip>
                </Space>
            </Space>
        </Space>
    );
};

export default OpenaiChatInterface;
