import React, { useState } from 'react';
import { Table, Switch, Space, Button, Message } from '@arco-design/web-react';
import { IconWechat } from '@arco-design/web-react/icon';

function EmployeeTable({ setselectedEmployee }) {
    const [employeeList, setEmployeeList] = useState([
        {
            key: '1',
            employee: '小希丽丽',
            account: 'iry-xiaoxilili',
            autoReply: true,
        },
        {
            key: '2',
            employee: '小希悦悦',
            account: null,
            autoReply: false,
        },
        {
            key: '3',
            employee: '新员工-3号',
            account: null,
            autoReply: false,
        },
    ]);

    const maxSeats = 5;
    const remainingSeats = maxSeats - employeeList.length;

    const handleAddEmployee = () => {
        if (remainingSeats > 0) {
            const newKey = employeeList.length + 1;
            const newEmployee = {
                key: newKey.toString(),
                employee: `新员工-${newKey}号`,
                account: null,
                autoReply: false,
            };
            setEmployeeList([...employeeList, newEmployee]);
            Message.success(`已添加 新员工-${newKey}号`);
        } else {
            Message.warning('已无剩余席位');
        }
    };

    const handleSwitchChange = (key) => {
        setEmployeeList((prevData) =>
            prevData.map((item) =>
                item.key === key ? { ...item, autoReply: !item.autoReply } : item
            )
        );
    };

    const handleSelectedEmployee = (row) => {
        setselectedEmployee(row)
    }

    const columns = [
        {
            title: '数字员工',
            dataIndex: 'employee',
            render: (employee) => <span>{employee}</span>,
        },
        {
            title: '绑定账号',
            dataIndex: 'account',
            render: (account) =>
                account ? (
                    <Space>
                        <IconWechat />
                        <span>{account}</span>
                    </Space>
                ) : (
                    <span>暂未绑定</span>
                ),
        },
        {
            title: '自动回复',
            dataIndex: 'autoReply',
            render: (_, record) => (
                <Switch
                    checked={record.autoReply}
                    disabled={!record.account} // 当 account 为空时禁用 Switch
                    onChange={() => handleSwitchChange(record.key)}
                />
            ),
        },
        {
            title: '编辑配置',
            dataIndex: 'edit',
            render: (_, row) => <Button type="text" onClick={() => handleSelectedEmployee(row)}>编辑</Button>,
        },
    ];

    return (
        <div style={{width:"60vw"}}>
            <Table columns={columns} data={employeeList} pagination={false} />
            <div style={{ marginTop: '10px', cursor: 'pointer' }}>
                <Button type="text" onClick={handleAddEmployee}>
                    添加员工
                </Button>
                <span style={{ float: 'right' }}>剩余席位 {remainingSeats} 个</span>
            </div>
        </div>
    );
}

export default EmployeeTable;
