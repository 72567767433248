import React from 'react';
import { Button, Layout, Space, Table } from '@arco-design/web-react';
import { IconPlus } from '@arco-design/web-react/icon';

const RechargeTable = () => {
    const columns = [
        {
            title: '充值日期',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: '充值金额',
            dataIndex: 'amount',
            key: 'amount',
        },
    ];

    const data = [
        {
            key: '1',
            date: '2024-05-01',
            amount: '588元',
        },
        {
            key: '2',
            date: '2024-06-01',
            amount: '598元',
        },
        {
            key: '3',
            date: '2024-07-01',
            amount: '668元',
        },
        {
            key: '4',
            date: '2024-08-01',
            amount: '718元',
        },
    ];

    return (
        <Layout>
            <Layout.Header>
                <Space
                    style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
                >
                    <h3>当前余额：228元</h3>
                    <Button type='primary' icon={<IconPlus />}>充值</Button>
                </Space>
            </Layout.Header>
            <Layout.Content>
                <Table
                    columns={columns}
                    data={data}
                    pagination={false} // 如果不需要分页，可以关闭分页
                    rowKey="key"
                    border
                />
            </Layout.Content>

        </Layout>

    );
};

export default RechargeTable;
