import React from 'react';
import { Layout, Tabs } from '@arco-design/web-react';
import PeopleCard from './components/PeopleCard';
const TabPane = Tabs.TabPane;
export const PeopleMarket = () => {
    return (
        <Layout>
            <Layout.Header>
                <Tabs defaultActiveTab='1'>
                    <TabPane key='1' title='医美护肤'>
                    </TabPane>
                    <TabPane key='2' title='留学教育'>
                    </TabPane>
                </Tabs>
            </Layout.Header>
            <Layout.Content style={{ 'margin': '5px' }}>
                <PeopleCard />
            </Layout.Content>
        </Layout>
    )
}