import React, {useEffect, useRef, useState} from 'react';
import {Input, Button, Space, Collapse} from '@arco-design/web-react';
import RoleInfo from './RoleConfig/RoleInfo';
import SalesProcess from './RoleConfig/SalesProcess';
import ProductList from './RoleConfig/ProductList';
import {IconLeft} from '@arco-design/web-react/icon';

const CollapseItem = Collapse.Item;
const RoleConfig = ({setShowPart, setChatInstruction, chatInstruction, selectedEmployee}) => {
    const [editing, setEditing] = useState(false);
    const [name, setName] = useState('新员工-3号 点我命名');
    const inputRef = useRef(null);

    const handleSave = () => {
        setEditing(false);
    };

    useEffect(() => {
        if (editing && inputRef.current) {
            inputRef.current.focus();
        }
    }, [editing]);

    useEffect(() => {
        if (selectedEmployee) {
            setName(selectedEmployee.employee)
        }
    }, [selectedEmployee])

    return (
        <Space direction={"vertical"} style={{height: "100vh",width:"38vw"}}>
            <Space style={{marginLeft: -10}}>
                <Button type='text' icon={<IconLeft/>} onClick={() => {
                    setShowPart(false)
                }} style={{marginTop: "5px"}}></Button>
                <Space direction='horizontal' align="center">
                    {editing ? (
                        <Input
                            ref={inputRef}
                            value={name}
                            onChange={setName}
                            onBlur={handleSave}
                            onPressEnter={handleSave}
                            placeholder={'新员工 点我命名'}
                            style={{marginTop: '10px', marginBottom: 10}}
                        />
                    ) : (
                        <h3 onClick={() => setEditing(true)}
                            style={{cursor: 'pointer', marginTop: "15px", marginBottom: "10px"}}>
                            {name}
                        </h3>
                    )}
                </Space>
            </Space>
            <Space direction={"vertical"} style={{
                width: "calc(38vw - 8px)",
                height: '93vh',
                overflowY: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: '#EEE white',
            }}>
                <RoleInfo setChatInstruction={setChatInstruction} chatInstruction={chatInstruction}/>
                <Collapse defaultActiveKey={['1']}>
                    <CollapseItem header="带货列表" key="1" extra={<Button type='text'>编辑</Button>} name={"products"}>
                        <ProductList/>
                    </CollapseItem>
                </Collapse>
                <SalesProcess/>
            </Space>
        </Space>
    );
};

export default RoleConfig;
