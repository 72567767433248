import { Layout } from '@arco-design/web-react';
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';

const SalesBarChart = () => {
    const data = [
        { month: '1月', value: 150 },
        { month: '2月', value: 100 },
        { month: '3月', value: 60 },
        { month: '4月', value: 80 },
        { month: '5月', value: 130 },
        { month: '6月', value: 240 },
        { month: '7月', value: 200 },
    ];

    return (
        <Layout>
            <Layout.Header>
                <h3>算力消耗</h3>
            </Layout.Header>
            <Layout.Content>
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="value" fill="#4D73FF" barSize={20} /> {/* 调整这里的 barSize 值 */}
                    </BarChart>
                </ResponsiveContainer>
            </Layout.Content>
        </Layout>
    );
};

export default SalesBarChart;
